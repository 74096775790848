import React, {useEffect, useRef, useState} from 'react'
import {Helmet} from "react-helmet"

import {
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Snackbar,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

//HOOKS
import {
    useTheme
} from '@material-ui/core/styles'
import {useHistory} from 'react-router-dom'

//RESOURCES
import bg from '../Resources/background.jpeg'
import {ReactComponent as Logo} from '../Resources/logo.svg'


export default function Login() {
    //HOOKS
    const theme = useTheme()
    const history = useHistory()

    // REFS
    const userInput = useRef()
    const passwordInput = useRef()
    const realForm = useRef()
    const realUserInput = useRef()
    const realPasswordInput = useRef()

    // STATES
    const [loading, setLoading] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    // EFFECTS
    useEffect(() => {
        document.title = 'Transtrip | Iniciar sesión'
    }, [])

    // FUNCTIONS
    const loginSubmit = e => {
        e.preventDefault()
        setLoading(true)
        
        const username = userInput.current.value
        const password = passwordInput.current.value
        fetch('https://karbooking.com/home/logout-json')
        .then(res => res.json())
        .catch(() => null)
        .finally(() => login(username, password))
    }

    const login = (username, password) => {
        const formData  = new FormData()
        formData.append("LoginForm[username]", username)
        formData.append("LoginForm[password]", password)
        formData.append("LoginForm[rememberMe]", 0)

        fetch('https://karbooking.com/home/external-login', {
            method: 'POST',
                body: formData,
                cache: "no-store",
                xhrFields: {
                    withCredentials: false
                },
                headers: {},
            })
            .then(res => res.json())
            .then(data => {
                if(data.success){
                    realUserInput.current.value = username
                    realPasswordInput.current.value = password
                    realForm.current.submit()
                }else{
                    setErrorMessage(data.code)
                    setOpenError(true)
                }
            })
            .catch(err => {
                setErrorMessage(err.message)
                setOpenError(true)
            })
            .finally(() => setLoading(false))
    }
    const handleClose = () => setOpenError(false)

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://transtrip.com.ar/login" />
                <meta name="description" content="Accedé a nuestra plataforma digital" />
                <meta name="robots" content="index, follow"></meta>
            </Helmet>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Box 
                position="fixed"
                top={0}
                bottom={0}
                left={0}
                right={0}
                style={{
                    background: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Container>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={10} sm={8} md={5}>
                            <Paper>
                                <Box 
                                    px={3}
                                    py={6}
                                    my={3}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    maxHeight="80vh"
                                    overflow="auto"
                                    style={{transition: "height 200ms ease-in-out"}}
                                >
                                    <Box component="a" href="/" width="45%">
                                        <Logo width="100%"/>
                                    </Box>
                                    <Box>
                                        <Box width={1} height={2} mt={1} mb={2} bgcolor={theme.palette.grey[200]} />
                                        <Typography variant="h6" color="primary" gutterBottom>Iniciar Sesión</Typography>
                                    </Box>
                                    {loading ? (
                                        <Box my={4}>
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <Box
                                            component="form"
                                            onSubmit={loginSubmit}
                                            display="flex"
                                            flexDirection="column"
                                            width={1}
                                            maxWidth={250}
                                        >
                                            <Box mt={4}/>
                                            <TextField inputRef={userInput} label="Usuario"/>
                                            <Box mt={2}/>
                                            <TextField inputRef={passwordInput} type="password" label="Clave"/>
                                            <Box mt={6}/>
                                            <Button variant="contained" color="primary" type="submit" disableElevation>Login</Button>
                                            <Box mt={4}/>
                                            <Button onClick={() => history.push("/recovery")} size="small" disableElevation>Olvide mi contraseña</Button>
                                        </Box>
                                    )}
                                    <Box
                                        display="none"
                                        component="form"
                                        ref={realForm}
                                        action="https://karbooking.com/home/login"
                                    >
                                        <input ref={realUserInput} type="text" />
                                        <input ref={realPasswordInput} type="password" />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
